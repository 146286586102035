<template>
<div class="config-wrap">
    <van-nav-bar title="我的" fixed :placeholder="true" />
    <div>
        <van-cell title="用户名" :value="userInfo.nickName" />
        <van-cell is-link @click="withdraw">
            <template #title>
                <span>可提现金额：</span>
                <van-tag type="danger">{{ amount }}</van-tag>
            </template>
        </van-cell>

        <!-- <van-cell center title="自动报价开关">
            <template #right-icon>
                <van-switch v-model="autoBid" size="24" @change="setAuto(1)" />
            </template>
        </van-cell> -->
        <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
        订单通知
    </van-divider>
    <van-cell-group style="margin-top: 20px;">
        <van-cell center title="新订单语音提醒开关">
            <template #right-icon>
                <van-switch v-model="autoAlarmNewOrder" size="24" @change="setAuto(3)" />
            </template>
        </van-cell>
        <van-cell center title="新订单微信通知开关">
            <template #right-icon>
                <van-switch v-model="autoWxAlarmNewOrder" size="24" @change="setAuto(4)" />
            </template>
        </van-cell>
    </van-cell-group>
    <van-cell-group style="margin-top: 20px;">
        <van-cell center title="中标语音提醒开关">
            <template #right-icon>
                <van-switch v-model="autoAlarmBidOrder" size="24" @change="setAuto(2)" />
            </template>
        </van-cell>
        
        <van-cell center title="中标微信通知开关">
            <template #right-icon>
                <van-switch v-model="autoWxAlarmBidOrder" size="24" @change="setAuto(5)" />
            </template>
        </van-cell>
    </van-cell-group>
        
        <!-- <van-cell is-link title="影院开放规则" @click="ruleDetail"></van-cell> -->
       
        <!-- <van-cell is-link @click="changePwd">
            <template #title>
                <span>修改密码：</span>
            </template>
        </van-cell> -->
    </div>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
        PC登录
    </van-divider>
    <van-cell-group style="margin-top: 20px;">
        <van-cell title="URL" :label="url">
            <template #right-icon>
                <van-icon  name="notes-o" @click="copy"/>
            </template>
        </van-cell>
        <van-cell title="登陆手机" :value="userInfo.mobile" />
        <van-cell title="登陆验证码" :value="code" />
    </van-cell-group>
    <div style="padding: 40px 10px;">
        <van-button round type="danger" style="width: 100%;" @click="beforeLogout">退出登录</van-button>
    </div>

    <!-- 修改密码
    <van-dialog v-model="showDialog" title="修改密码" @close="showDialog = false" @confirm="handleConfirm" @cancel="handleCancel">
        <van-field v-model="password" type="password" label="密码" placeholder="请输入密码" />
        <template #footer>
            <van-button text @click="handleCancel">取消</van-button>
            <van-button type="primary" @click="handleConfirm">修改</van-button>
        </template>
    </van-dialog>
     -->
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';

import Api from '@/api-service'

export default {
    data() {
        return {
            show: false,
            amount: 0.0,
            showDialog: false, // 控制对话框显示
            password: '', // 绑定密码输入框的值
            code: '',
            autoBid: false,
            autoAlarmNewOrder: false,
            autoAlarmBidOrder: false,
            autoWxAlarmNewOrder: false,
            autoWxAlarmBidOrder: false,
            url:'http://merchant.24xiaoshi.online/',
        };
    },
    computed: {
        ...mapGetters(['hasGetUserInfo']),
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    created() {
        // 如果没有登录，跳转到登录页
        this.getWithdrawAmount()
        this.makeCode()
        this.autoBid = this.userInfo.auto == 1
        this.autoAlarmNewOrder = this.userInfo.order_tip_type == 1
        this.autoAlarmBidOrder = this.userInfo.alarm_type == 1
        this.autoWxAlarmNewOrder = this.userInfo.wx_new_order_type == 1
        this.autoWxAlarmBidOrder = this.userInfo.wx_bid_order_type == 1
    },
    methods: {
        ...mapActions(['logout']),
        beforeLogout() {
            this.$dialog
                .confirm({
                    title: '系统提示',
                    message: '确认退出登录吗?',
                })
                .then(() => {
                    this.logout();
                });
        },
        ruleDetail() {
            this.$router.push("/cinema/rule")
        },
        withdraw() {
            this.$router.push({
                path: '/withdraw',
                query: {
                    amount: this.amount
                }
            })
        },
        setAuto(item) {
            let data = {}
            if (item == 1) {
                data.bid_type = this.autoBid ? 1 : 2
            } else if (item == 2) {
                data.alarm_type = this.autoAlarmBidOrder ? 1 : 2
            } else if (item == 3) {
                data.order_tip_type = this.autoAlarmNewOrder ? 1 : 2
            } else if (item == 4) {
                data.wx_new_order_type = this.autoWxAlarmNewOrder ? 1 : 2
            } else if (item == 5) {
                data.wx_bid_order_type = this.autoWxAlarmBidOrder ? 1 : 2
            }

            Api.setAutoStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '操作成功'
                });

                // if (data.bid_type == 2) {
                //     if(item == 1) {
                //         this.userInfo.auto = 2
                //     } else if (item == 2) {
                //         this.userInfo.alarm_type = 2
                //     } else if (item == 3) {
                //         this.userInfo.order_tip_type = 2
                //     }

                // } else {
                //     if(item == 1) {
                //         this.userInfo.auto = 1
                //     } else if (item == 2) {
                //         this.userInfo.alarm_type = 1
                //     } else if (item == 3) {
                //         this.userInfo.order_tip_type = 1
                //     }
                // }

                console.log(this.userInfo)

            })
        },
        getWithdrawAmount() {

            Api.getWithdrawAmount({}).then(res => {
                this.amount = res.amount
            })
        },
        makeCode() {
            Api.makeCode({}).then(res => {
                this.code = res.code
            })
        },
        changePwd() {
            //   this.$router.push({path:'/change/pwd'})
        },
        handleConfirm() {
            // 确认按钮的逻辑
            console.log('新密码:', this.password);
            // 这里可以添加发送新密码到服务器的代码
            this.showDialog = false;
        },
        handleCancel() {
            // 取消按钮的逻辑
            this.showDialog = false;
        },
        async copy() {
            try {
                const content = this.url;
                // 调用浏览器 API 复制内容到剪贴板
                await navigator.clipboard.writeText(content);
                // 复制成功后的提示
                this.$toast.success('内容已复制到剪贴板');
            } catch (err) {
                // 复制失败的错误处理
                console.error('复制失败:', err);
                this.$toast.fail('复制失败');
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.custom-input {
    width: 80%;
    /* 或者根据需要设置具体宽度 */
    height: 100%;
    /* 或者根据需要设置具体高度 */
}

.config-wrap {}
</style>
